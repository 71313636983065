import React, { Component } from "react";
import Header from "../Header";
import { Container, Row, Col } from "reactstrap";
import "./Infos.scss";

class Infos extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="infos">
        <Header />
        <Container>
          <Row>
            <Col lg={{ size: 5 }} className="text-infos">
              <div>
                <h5 className="text-center">A LOUER A GOURETTE (64)</h5>
                <h5 className="text-center">Résidence Pene Medaa</h5>
                <p className="text-center text-strong">
                  {" "}
                  STUDIO 6 PLACES TOUT EQUIPE, GRAND BALCON, PLEIN SUD, AU PIED
                  DES PISTES AVEC PARKING PRIVE COUVERT.
                </p>
                <p className="text-center text-red">
                  Le parking est fermé pour cause de travaux pour une durée
                  indéterminée.
                </p>
                <div className="text-plan">
                  <a
                    href="https://www.gourette.com/preparer-son-sejour/infos-pratiques/ou-stationner-2/"
                    target="_blank"
                  >
                    --> Cliquez ici pour voir les stationnements de Gourette
                  </a>
                </div>
                Coin cuisine équipée :
                <ul>
                  <li>évier</li>
                  <li>réfrigérateur</li>
                  <li>plaques vitrocéramiques</li>
                  <li>four</li>
                  <li>micro-ondes</li>
                  <li>cafetière normale + Nespresso</li>
                  <li>bouilloire</li>
                  <li>service à raclette</li>
                  <li>grille-pain</li>
                </ul>
                <br />
              </div>
            </Col>
            <Col lg={{ size: 5, offset: 2 }} className="text-infos">
              <p className="text-semaine">
                PRIX SEMAINE HIVER : 450 Euros
                <br />
                PRIX SEMAINE ETE : 220 Euros
                <br />
                PRIX WEEK-END : 150 Euros
                <br />
                <span className="arrhes">
                  Versement de 25% d'arrhes après confirmation de la réservation
                  et encaissées à la remise des clés.
                </span>
              </p>
              <div className="text-plan">
                <a href="/images/plan_de_situation.png" target="_blank">
                  --> Cliquez ici pour le plan de situation
                </a>
              </div>
              {/* <div className="map-responsive">
                <iframe title="Adresse" src="https://www.google.com/maps/embed?pb=!1m17!1m11!1m3!1d1339.6401363463585!2d-0.3315809552362789!3d42.95777996395884!2m2!1f0!2f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd57b8c310509c75%3A0xc8d358e9f20eb2f!2sGourette%2C+64440+Eaux-Bonnes%2C+France!5e1!3m2!1sfr!2sis!4v1546286822113" width="400" height="300" frameorder="0" allowFullScreen></iframe>
              </div> */}
              <div className="text-center">
                Les 4 lits d'une place sont équipés de petites couettes et
                d'oreillers. Le canapé lit est équipé d'une grande couette et
                d'oreillers.
                <br />
                <br />
                <p className="text-strong text-red">
                  Nous ne fournissons pas les draps, ni les housses d'oreillers
                  et de couettes. Pour l'hygiène de tous, nous vous remercions
                  d'apporter IMPERATIVEMENT, vos draps, housses de couettes et
                  d'oreillers.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Infos;
